body {
  background-color: #ffffff;
  color: #000000;
}

.content {
  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.music-container {
  width: 70%
}

.title {
  font-size: 6em;
  font-weight: 700;
}

@media only screen and (max-width: 768px) {
  .music-container {
    width: 90%;
  }
}